<template>
  <el-menu
    class="el-menu-vertical-demo"
    :default-active="$route.path"
    @open="handleOpen"
    @close="handleClose"
    router
  >
    <div v-for="(item, index) in menuList" :key="index">
      <el-submenu :index="item.name" v-if="item.children">
        <template #title>
          <span>{{ item.name }}</span>
        </template>
        <el-menu-item
          :index="res.uri"
          v-for="(res, i) in item.children"
          :key="i"
          :title="res?.name?.length > 8 ? res?.name : ''"
          >{{ res.name }}</el-menu-item
        >
      </el-submenu>

      <el-menu-item
        :index="item.uri"
        :title="res?.name?.length > 8 ? res?.name : ''"
        v-else
      >
        <template #title>{{ item.name }}</template>
      </el-menu-item>
    </div>
  </el-menu>
</template>

<script>
export default {
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.el-icon-arrow-down) {
  color: #fff !important;
}
.el-menu-vertical-demo > div > .el-menu-item.is-active {
  height: 60px;
  background-color: #167eb5;
  color: #fff;
  font-size: 20px;
}
.el-menu-vertical-demo > div > .el-menu-item {
  height: 60px;
  font-size: 20px;
}
</style>
